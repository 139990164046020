
\:root, body
  // colors
  --main-theme-color: #104283
  --main-theme-transparent-color: rgba(16, 66, 131, 0.85)
  --main-theme-darker-color: #0a3670
  --main-theme-lighter-color: #104e8f
  --main-theme-ultra-light-color: #DEEBFF

  --popup-bg-color: white
  --popup-bg-transparent-color: rgba(255, 255, 255, 0.6)
  --popup-wrap-color: rgba(0, 0, 0, 0.6)
  --popup-wrap-light-color: rgba(0, 0, 0, 0.3)

  --online-bg-color: #00AC00

  --grey-color: #f5f5f5
  --grey-dark-color: #373737
  --grey-medium-color: #a5a5a5
  --grey-light-color: #cecece

  //fonts
  --font-family-main: 'Lato', sans-serif

  --font-size-m: 16px
  --font-size-l: 24px

  // borders
  --main-theme-border: 1px solid var(--main-theme-color)
  --main-theme-lighter-border: 1px solid var(--main-theme-lighter-color)
  --grey-border: 1px solid var(--grey-color)
  --grey-light-border: 1px solid var(--grey-light-color)
  --default-border: 1px solid black

  // shadows
  --block-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1)
  --white-text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black
