
.disclaimerPopup
  position: fixed
  top: 0
  left: 0
  width: 100vw
  min-height: 100%
  max-height: 100%
  background-color: var(--popup-wrap-color)
  box-shadow: 1px 1.5px 17.5px 1px rgba(0, 0, 0, 0.18)
  display: flex
  z-index: 99999
  font-family: lato, sans-serif
  color: var(--grey-dark-color)
  line-height: 1.5
  overflow-y: auto
  .disclaimerError
    position: absolute
    font-size: 12px
    line-height: 14px
    color: #a23330
    margin: 5px 30px
    max-width: 490px

  .paragraph_header
    color: black
    font-size: 1.17em
    font-weight: bold

  .modalDialog
    margin: auto
    width: 60vw
    max-width: 720px
    padding: 0 40px
    min-height: 90vh
    max-height: 90vh
    background-color: white
    border: 1px solid rgba(0, 0, 0, 0.18)
    overflow-y: auto

    &::-webkit-scrollbar
      display: none

    i
      display: block
      margin: 5px 20px

    .modalHeader
      display: flex
      justify-content: space-between
      margin: 20px 25px
      height: auto
      min-height: 100px

    .contentModalDisclaimer
      display: block
      margin: 20px 25px
      padding-right: 5px
      height: auto
      max-height: calc(90vh - 400px)
      overflow-y: auto

      .paragraph_header
        margin: 30px 0 10px
        font-size: 25px

      p
        margin-bottom: 8px

    .confirmation
      width: 100%
      border-top: solid 0.5px var(--grey-color)
      padding: 20px 25px
      height: auto
      min-height: 100px

      a
        color: var(--main-theme-color)
        text-decoration: none
      &:visited
        color: var(--main-theme-color)
      &:focus
        color: var(--main-theme-color)

      .confirmationLine
        display: flex

      .buttonLine
        display: flex
        padding: 35px 25px 0

        .button
          &:first-of-type
            margin-right: 10px
            width: 200px

            &:hover
              border: var(--main-theme-border)
              background-color: white
              color: var(--main-theme-color)

          &:last-of-type
            border: var(--main-theme-border)
            background-color: white
            color: var(--main-theme-color)

            &:hover
              background-color: var(--main-theme-color)
              color: white

.notAvailable
  display: none

@media screen and (max-width: 767px)
  .disclaimerPopup
    width: 100%
    height: 100%
    .modalDialog
      position: fixed
      padding: 0 10px
      width: 100%
      min-height: 100%
      max-height: 100%
      top: 0
      left: 0
      .modalHeader
        flex-direction: column-reverse
        align-items: start
      .contentModalDisclaimer
        max-height: calc(90vh - 400px)
        .langLinks
          margin: 10px 0
          scale: 1.2
      .confirmation
        .buttonLine
          .button
            width: 45%

@media screen and (min-width: 767px) and (max-width: 1024px)
  .disclaimerPopup
    .modalDialog
      width: 80vw
