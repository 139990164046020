.oopsContainer
  position: relative
  top: 0
  left: 0
  right: 0
  bottom: 0
  display: flex
  flex-direction: column
  justify-content: center
  font-family: 'Lato', sans-serif


  img
    margin: 0 auto -19px
    width: 100px
    height: 100px
    z-index: 2


  .errorMessage
    text-align: center
    margin: auto
    font-size: 32px
    border: 1px solid rgba(0,0,0,0.1)
    border-radius: 6px
    padding: 32px


  .menu
    display: flex
    border: 1px solid rgba(0,0,0,0.1)
    border-radius: 6px
    margin: 20px auto
    padding: 10px


    div
      display: flex
      flex-direction: column
      margin: 20px

      a
        color: #104283
        text-decoration: none
        font-size: 20px

      p
        font-size: 24px
        font-width: bold
        margin-top: -10px


